.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.indicator {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
}
.indicator-red {
  background: rgb(249, 225, 229);
  color: rgb(224, 131, 148);
  border: 1px solid rgb(224, 131, 148);
}
.indicator-yellow {
  background: rgb(253, 230, 216);
  color: rgb(245, 134, 71);
  border: 1px solid rgb(253, 230, 216);
}
.indicator-orange {
  background: rgb(245, 134, 71);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(224, 131, 148);
}
.indicator-green {
  background: rgb(217, 248, 235);
  color: rgb(32, 216, 139);
  border: 1px solid rgb(217, 248, 235);
}
.text-status-red {
  color: rgb(224, 131, 148);
  font-weight: bold;
  font-size: 12px;
}
.text-status-yellow {
  color: rgb(245, 134, 71);
  font-weight: bold;
  font-size: 12px;
}
.text-status-orange {
  color: rgb(224, 131, 148);
  font-weight: bold;
  font-size: 12px;
}
.text-status-green {
  color: rgb(32, 216, 139);
  font-weight: bold;
  font-size: 12px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
